import { all, takeLatest, fork, call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { crawlAdditionalRequestService } from "@iso/services";

export function* crawlAdditionalRequest() {
  yield takeLatest(actions.CRAWL_ADDITIONAL_REQUEST_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      modeReports,
      checkinDateTemairazuStart,
      checkinDateTemairazuEnd,
      reservationDateTemairazu,
      cancellationDateTemairazuStart,
      cancellationDateTemairazuEnd,
      checkinMonthDorTemairazu,
      dateOnHandTlStart,
      dateOnHandTlEnd,
      monthDorNumberOfNightTl,
      aggregationPeriodTlStart,
      aggregationPeriodTlEnd,
      comparisonPeriodTlStart,
      comparisonPeriodTlEnd,
      useDateTlStart,
      useDateTlEnd,
      stayDateTemairazuStart,
      stayDateTemairazuEnd,
      monthLeadTimeTl,
      leadTimeSummaryMonthTemairazu,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlAdditionalRequestService.crawlAdditionalRequest, {
        storeIds,
        modeReports,
        checkinDateTemairazuStart,
        checkinDateTemairazuEnd,
        reservationDateTemairazu,
        cancellationDateTemairazuStart,
        cancellationDateTemairazuEnd,
        checkinMonthDorTemairazu,
        dateOnHandTlStart,
        dateOnHandTlEnd,
        monthDorNumberOfNightTl,
        aggregationPeriodTlStart,
        aggregationPeriodTlEnd,
        comparisonPeriodTlStart,
        comparisonPeriodTlEnd,
        useDateTlStart,
        useDateTlEnd,
        stayDateTemairazuStart,
        stayDateTemairazuEnd,
        monthLeadTimeTl,
        leadTimeSummaryMonthTemairazu,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_ADDITIONAL_REQUEST_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_ADDITIONAL_REQUEST_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlAdditionalRequest() {
  yield takeLatest(actions.GET_CRAWL_ADDITIONAL_REQUEST_REQUEST, function* () {
    try {
      const report = yield call(crawlAdditionalRequestService.getCrawlAdditionalRequest);
      yield put({
        type: actions.GET_CRAWL_ADDITIONAL_REQUEST_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_ADDITIONAL_REQUEST_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlAdditionalRequest)]);
  yield all([fork(getCrawlAdditionalRequest)]);
}
