import { all, takeEvery, put, fork, call, takeLatest } from "redux-saga/effects";

import { autoCopyTaskService } from "@iso/services";
import actions from "@iso/redux/autoCopyTask/actions";

export function* getAutoCopyTasks() {
  yield takeEvery(actions.GET_AUTO_COPY_TASK_REQUEST, function* ({payload}) {
    const {
      keywordId,
      types,
      isActive,
      size,
      page,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(autoCopyTaskService.getAutoCopyTasks, {
        keywordId,
        types,
        isActive,
        size,
        page,
        resolve,
        reject,
      })
      resolve(response)
      yield put({
        type: actions.GET_AUTO_COPY_TASK_SUCCESS,
        autoCopyTasks: response ? response.cronjobs : [],
        total: response ? response.total : 0
      });
    } catch (e) {
      yield put({type: actions.GET_AUTO_COPY_TASK_ERROR});
      reject(e)
    }
  })
}

function* createAutoCopyTask() {
  yield takeEvery(actions.CREATE_AUTO_COPY_TASK_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(autoCopyTaskService.createAutoCopyTask, {...data});
      yield put({
        type: actions.CREATE_AUTO_COPY_TASK_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.CREATE_AUTO_COPY_TASK_ERROR,
        error: error.response,
      });
      reject({});
    }
  });
}

function* updateAutoCopyTask() {
  yield takeEvery(actions.UPDATE_AUTO_COPY_TASK_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(autoCopyTaskService.updateAutoCopyTask, {...data});
      yield put({
        type: actions.UPDATE_AUTO_COPY_TASK_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.UPDATE_AUTO_COPY_TASK_ERROR,
        error: error.response,
      });
      reject({});
    }
  });
}

export function* getAutoCopyTaskDetail() {
  yield takeEvery(actions.DETAIL_AUTO_COPY_TASK_REQUEST, function* ({payload}) {
    const {resolve, reject, autoCopyTaskId} = payload;
    try {
      let response = null;
      if (autoCopyTaskId !== null && autoCopyTaskId !== undefined) {
        response = yield call(autoCopyTaskService.fetchAutoCopyTask, {
          autoCopyTaskId
        })

        if (response.executedTeam !== null && response.executedTeam !== undefined) {
          response.executedTeam = response.executedTeam.split(",")
        } else {
          response.executedTeam = []
        }
      }
      resolve(response)
      yield put({
        type: actions.DETAIL_AUTO_COPY_TASK_SUCCESS,
        autoCopyTask: response
      });
    } catch (e) {
      yield put({type: actions.DETAIL_AUTO_COPY_TASK_ERROR});
      reject(e)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getAutoCopyTasks),
    fork(createAutoCopyTask),
    fork(updateAutoCopyTask),
    fork(getAutoCopyTaskDetail),
  ]);
}
