import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { twoFactorAuthenticationService } from "@iso/services";

export function* requestCodeAuthentication() {
  yield takeLatest(actions.REQUEST_CODE_AUTHENTICATION_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(twoFactorAuthenticationService.requestCodeAuthentication, {
        storeId,
        otaId,
        resolve,
        reject
      });
      yield put({
        type: actions.REQUEST_CODE_AUTHENTICATION_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.REQUEST_CODE_AUTHENTICATION_ERROR });
      reject(e);
    }
  });
}

export function* updateCodeAuthentication() {
  yield takeLatest(actions.UPDATE_CODE_AUTHENTICATION_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      code,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(twoFactorAuthenticationService.updateCodeAuthentication, {
        storeId,
        otaId,
        code,
        resolve,
        reject
      });
      yield put({
        type: actions.UPDATE_CODE_AUTHENTICATION_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.UPDATE_CODE_AUTHENTICATION_ERROR });
      reject(e);
    }
  });
}

export function* getInfoAuthenticationCurrent() {
  yield takeLatest(actions.GET_INFO_AUTHENTICATION_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      type,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(twoFactorAuthenticationService.getInfoAuthenticationCurrent, {
        storeId,
        otaId,
        type,
        resolve,
        reject
      });
      yield put({
        type: actions.GET_INFO_AUTHENTICATION_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.GET_INFO_AUTHENTICATION_ERROR });
      reject(e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(requestCodeAuthentication)]);
  yield all([fork(updateCodeAuthentication)]);
  yield all([fork(getInfoAuthenticationCurrent)]);
}
