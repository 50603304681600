import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlAdditionalRequestService extends BaseService {
  crawlAdditionalRequest = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_ADDITIONAL_REQUEST, data);
  }
  getCrawlAdditionalRequest = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_ADDITIONAL_REQUEST);
  }
}
