const actions = {
  CRAWL_ADDITIONAL_REQUEST_REQUEST: "CRAWL_ADDITIONAL_REQUEST_REQUEST",
  CRAWL_ADDITIONAL_REQUEST_SUCCESS: "CRAWL_ADDITIONAL_REQUEST_SUCCESS",
  CRAWL_ADDITIONAL_REQUEST_ERROR: "CRAWL_ADDITIONAL_REQUEST_ERROR",

  GET_CRAWL_ADDITIONAL_REQUEST_REQUEST: "GET_CRAWL_ADDITIONAL_REQUEST_REQUEST",
  GET_CRAWL_ADDITIONAL_REQUEST_SUCCESS: "GET_CRAWL_ADDITIONAL_REQUEST_SUCCESS",
  GET_CRAWL_ADDITIONAL_REQUEST_ERROR: "GET_CRAWL_ADDITIONAL_REQUEST_ERROR",

  DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_REQUEST: "DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_REQUEST",
  DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_SUCCESS: "DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_SUCCESS",
  DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_ERROR: "DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_ERROR",

  crawlAdditionalRequest: (payload) => ({
    type: actions.CRAWL_ADDITIONAL_REQUEST_REQUEST,
    payload,
  }),

  getCrawlAdditionalRequest: (payload) => ({
    type: actions.GET_CRAWL_ADDITIONAL_REQUEST_REQUEST,
    payload,
  }),

  downloadCrawlAdditionalRequest: (payload) => ({
    type: actions.DOWNLOAD_CRAWL_ADDITIONAL_REQUEST_REQUEST,
    payload,
  }),
};
export default actions;
