import actions from "./actions";

const initialState = {
  error: null,
  loading: false,
  crawler: {},
};

export default function twoFactorAuthenticationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST_CODE_AUTHENTICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.REQUEST_CODE_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.REQUEST_CODE_AUTHENTICATION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_CODE_AUTHENTICATION_REQUEST:
      return {
        ...state,
      };
    case actions.UPDATE_CODE_AUTHENTICATION_SUCCESS:
      return {
        ...state,
      };
    case actions.UPDATE_CODE_AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_INFO_AUTHENTICATION_REQUEST:
      return {
        ...state,
      };
    case actions.GET_INFO_AUTHENTICATION_SUCCESS:
      return {
        ...state,
      };
    case actions.GET_INFO_AUTHENTICATION_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
