import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class TwoFactorAuthenticationService extends BaseService {
  requestCodeAuthentication = (data) => {
    return this.axios.post(API_ENDPOINTS.REQUEST_CODE_AUTHENTICATION, data);
  }
  updateCodeAuthentication = (data) => {
    return this.axios.post(API_ENDPOINTS.UPDATE_CODE_AUTHENTICATION, data);
  }
  getInfoAuthenticationCurrent = (data) => {
    return this.axios.post(API_ENDPOINTS.GET_INFO_AUTHENTICATION_CURRENT, data);
  }
}
