const actions = {
  REQUEST_CODE_AUTHENTICATION_REQUEST: "REQUEST_CODE_AUTHENTICATION_REQUEST",
  REQUEST_CODE_AUTHENTICATION_SUCCESS: "REQUEST_CODE_AUTHENTICATION_SUCCESS",
  REQUEST_CODE_AUTHENTICATION_ERROR: "REQUEST_CODE_AUTHENTICATION_ERROR",

  UPDATE_CODE_AUTHENTICATION_REQUEST: "UPDATE_CODE_AUTHENTICATION_REQUEST",
  UPDATE_CODE_AUTHENTICATION_SUCCESS: "UPDATE_CODE_AUTHENTICATION_SUCCESS",
  UPDATE_CODE_AUTHENTICATION_ERROR: "UPDATE_CODE_AUTHENTICATION_ERROR",

  GET_INFO_AUTHENTICATION_REQUEST: "GET_INFO_AUTHENTICATION_REQUEST",
  GET_INFO_AUTHENTICATION_SUCCESS: "GET_INFO_AUTHENTICATION_SUCCESS",
  GET_INFO_AUTHENTICATION_ERROR: "GET_INFO_AUTHENTICATION_ERROR",

  requestCodeAuthentication: (payload) => ({
    type: actions.REQUEST_CODE_AUTHENTICATION_REQUEST,
    payload,
  }),

  updateCodeAuthentication: (payload) => ({
    type: actions.UPDATE_CODE_AUTHENTICATION_REQUEST,
    payload,
  }),

  getInfoAuthenticationCurrent: (payload) => ({
    type: actions.GET_INFO_AUTHENTICATION_REQUEST,
    payload,
  }),
};
export default actions;
